<template>
  <div class=" mx-2 ">
    <v-card class="">
      <v-row class=" mx-2">
        <v-col lg="6" xl="6" md="6" sm="12" xs="12">
          <v-col class="pa-0">
            <div class="d-flex justify-space-between align-center">
              <v-btn color="primary" icon @click="shiftCalendar('prev')"
                ><v-icon dark>mdi-chevron-left</v-icon></v-btn
              >
              <h1 class="text-gray-100">{{ formatDateYear(today) }}</h1>
              <v-btn color="primary" icon @click="shiftCalendar('next')"
                ><v-icon dark>mdi-chevron-right</v-icon></v-btn
              >
            </div>

            <v-flex>
              <v-sheet height="450" elevation="4">
                <v-calendar
                  v-if="!isCalendarFocus"
                  :now="today"
                  :value="today"
                  color="primary"
                >
                  <template v-slot:day="{ date }">
                    <template v-if="tracked[date]">
                      <div class="mt-n3" style="height: 50px; overflow-y: auto">
                        <v-menu
                          v-for="(event, i) in tracked[date]"
                          :key="i"
                          offset-x
                        >
                          <template v-slot:activator="{ on }">
                            <div class="px-2">
                              <div
                                @click="getBday(tracked[date], date)"
                                v-on="on"
                                class="text-caption text-truncate rounded-lg white--text mt-1 pa-1"
                                style="
                                  background-color: #35adfc;
                                  cursor: pointer;
                                "
                              >
                                <v-icon
                                  color="white"
                                  class="mr-2 mt-n1"
                                  size="15"
                                  >mdi-cake</v-icon
                                >
                                {{ toTitleCase(event.name) }}
                              </div>
                            </div>
                          </template>
                          <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar color="primary" dark>
                              <v-toolbar-title>{{
                                toTitleCase(event.name)
                              }}</v-toolbar-title>
                              <v-spacer></v-spacer>

                              <v-icon>cake</v-icon>
                              <v-icon>cake</v-icon>
                            </v-toolbar>
                            <v-card-title primary-title>
                              <span>Happy birthday!!!</span>
                            </v-card-title>
                            <v-card-actions>
                              <v-btn text color="error"> Close </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </div>
                    </template>
                  </template>
                </v-calendar>
                <v-expand-transition>
                  <div v-show="isCalendarFocus">
                    <div>
                      <v-btn
                        @click="isCalendarFocus = false"
                        class="mx-2"
                        color="red"
                        icon
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div class="text-center pa-2">
                      <v-btn class="mx-2" fab dark color="primary">
                        {{ getDayOnDate() }}
                      </v-btn>
                    </div>
                    <div class="mt-6 mx-4">
                      <div class="d-flex justify-space-between">
                        <div>Happy birthday!!!</div>
                        <div>
                          <v-icon color="primary">cake</v-icon>
                          <v-icon color="primary">cake</v-icon>
                        </div>
                      </div>
                      <div
                        class="text-subtitle-1 text-truncate rounded-lg white--text mt-2 pa-2"
                        style="background-color: #35adfc; "
                        v-for="(item, i) in bdays"
                        :key="i"
                      >
                        {{ toTitleCase(item.name) }}
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </v-sheet>
            </v-flex>
          </v-col>
        </v-col>
        <v-col lg="6" xl="6" md="6" sm="12" xs="12">
          <v-card class="card-style">
            <v-row>
              <v-col cols="12" class="pt-16">
                <PieChart :data="maleFemaleData" />
              </v-col>
              <v-col cols="12 ">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="grey--text px-2 text-subtitle-1 font-weight-medium w-full d-flex justify-center"
                  >Total Male/ Female Employees
                </v-list-item-title>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col lg="6" xl="6" md="6" sm="12" xs="12">
          <v-card class="card-style">
            <v-col cols="12">
              <BarChart />
            </v-col>
            <v-row>
              <v-col cols="12 ">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="grey--text px-2 text-caption font-weight-medium w-full d-flex justify-center"
                  >Total number of employees</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col lg="6" xl="6" md="6" sm="12" xs="12">
          <v-card class="card-style">
            <!-- <v-col cols="12"> <PieChart :pieData="datas"/></v-col> -->
            <PieChart :data="datas" />
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="grey--text px-2 text-subtitle-1 font-weight-medium w-full d-flex justify-center"
                  >Total Number of Employees Per Employment
                  Status</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <ViewSetDirectHeadDialog :data="head_data" />
  </div>
</template>

<script>
import BarChart from "../../components/Charts/samples/BarChart.vue";
// import PieChart from "../../components/Charts/samples/PieChart.vue";
import ViewSetDirectHeadDialog from "../../components/Dialogs/Views/ViewSetDirectHeadDialog.vue";
import PieChart from "../../components/Charts/NewCharts/Pie.vue";
// import HCPieChart from "../../components/Charts/HighCharts/PieChart.vue";
// import AreaChart from "../../components/Charts/samples/AreaChart.vue";

export default {
  components: {
    BarChart,
    // PieChart,
    ViewSetDirectHeadDialog,
    PieChart,
    // highcharts: Chart,
    // HCPieChart,
    // AreaChart,
  },
  data: () => ({
    mini: false,
    upcoming_bdays: [],
    hasUpcomingBday: false,
    head_data: {},
    dispatchWorks: null,
    cancelledWorks: null,
    completedWorks: null,
    maleCnt: 0,
    femaleCnt: 0,
    datas: {},
    maleFemaleData: {},
    label: [],
    top_clients: [],
    today: null,
    activeCalendar: null,
    tracked: {},
    isCalendarFocus: false,
    bdays: [],
    colors: ["#1867c0", "#fb8c00", "#000000"],
    category: ["Development", "Meetings", "Slacking"],
    data: [30, 12, 58, 44, 12, 46, 89, 67],
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    title: "Test chart",
    sideText: "Some data",
    color: ["#2D9CDB"],
  }),
  computed: {
    // convert the list of events into a map of lists keyed by date
    eventsMap() {
      const map = {};
      this.events.forEach((e) => (map[e.date] = map[e.date] || []).push(e));
      return map;
    },
  },
  methods: {
    initialize() {
      this.getUpComingBdays();
      this.getMaleFemaleCount();
      this.getEmployeeStats();
      // this.getMyDirectHeadCount();
      this.getIfHasDirectHead();
      this.getMyDirectHead();

      // this.datas = [300, 50, 100];
      // this.label = ["Plantilla", "COS", "JO"];
      // let statuses = [1,3,5,6]
      // this.axiosCall("/dashboard/getAllWorkStatusCount", "POST", statuses).then((res) => {
      //   if (res){
      //       this.scheduledWorks = res.data.worksArray[0].work_detail_status_count
      //       this.dispatchWorks = res.data.worksArray[1].work_detail_status_count
      //       this.cancelledWorks = res.data.worksArray[2].work_detail_status_count
      //       this.completedWorks = res.data.worksArray[3].work_detail_status_count
      //       this.$refs.DoughnutChart1.renderGraph(res.data.total.total_works,res.data.worksArray[0].work_detail_status_count)
      //       this.$refs.DoughnutChart2.renderGraph(res.data.total.total_works,res.data.worksArray[1].work_detail_status_count)
      //       this.$refs.DoughnutChart3.renderGraph(res.data.total.total_works,res.data.worksArray[2].work_detail_status_count)
      //       this.$refs.DoughnutChart4.renderGraph(res.data.total.total_works,res.data.worksArray[3].work_detail_status_count)
      //   }
      // });
      // this.axiosCall("/dashboard/getAllTopClients", "GET").then((res) => {
      //   if (res){
      //       this.top_clients = res.data
      //   }
      // });
      // this.axiosCall("/dashboard/getAllWorkRequest", "GET",).then((res) => {
      //   if (res){
      //       res.data.forEach(item => {
      //         this.label.push(item.request_type_description)
      //         this.datas.push(item.work_request_count)
      //       });
      //     this.$refs.PieChart.renderGraph(this.datas,this.label)
      //   }
      // });
      // console.log(this.$store.getters.getIsAuthenticated);
    },

    getDayOnDate() {
      if (this.selectedDay) {
        let d = this.selectedDay.substr(8, 2);
        return parseInt(d);
      }
    },

    getBday(event, date) {
      this.isCalendarFocus = true;
      this.selectedDay = date;
      this.bdays = event;
      console.log(event, date);
    },

    open(event) {
      alert(event.title);
    },
    getUpComingBdays() {
      this.axiosCall("/user-details/getBdayToday", "GET").then(
        (res) => {
          if (res) {
            this.today = res.data.today;
            this.activeCalendar = res.data.today;
            this.tracked = res.data.data;
            console.log("tracked", this.tracked);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    shiftCalendar(move) {
      this.isCalendarFocus = false;
      let date = new Date(this.activeCalendar);
      if (move == "prev") {
        date.setMonth(date.getMonth() - 1);
      } else {
        date.setMonth(date.getMonth() + 1);
      }
      this.activeCalendar = date.toLocaleDateString();

      this.axiosCall("/user-details/shiftCalendar/" + date, "GET").then(
        (res) => {
          if (res) {
            this.today = res.data.today;
            this.tracked = res.data.data;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    getMyDirectHead() {
      this.axiosCall("/user-details/getMyDirectHead", "GET").then(
        (res) => {
          console.log(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    // getMyDirectHeadCount() {
    //   this.axiosCall("/my-direct-head/getMyDirectHeadCount", "GET").then(
    //     (res) => {
    //       if (res.data) {
    //         this.getIfHasDirectHead();
    //         Object.assign(this.head_data, { should_open: false });
    //       } else {
    //         this.getIfHasDirectHead();
    //         Object.assign(this.head_data, { should_open: true });
    //       }
    //     }
    //   );
    // },

    getIfHasDirectHead() {
      this.axiosCall("/my-direct-head/getIfHasDirectHead", "GET").then(
        (res) => {
          console.log("hasdirecthead", res.data);
          this.head_data = res.data;
        }
      );
    },

    getEmployeeStats() {
      this.axiosCall("/user-details/getEmployeeStats", "GET").then(
        (res) => {
          if (res.data) {
            this.datas = {
              label: [
                "Permanent",
                "Temporary",
                "Casual",
                "COS",
                "JO",
                "Coterminous",
              ],

              data: [
                res.data.permanent,
                res.data.temporary,
                res.data.casual,
                res.data.COS,
                res.data.JO,
                res.data.coterminous,
              ],
            };
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    getMaleFemaleCount() {
      this.axiosCall("/user-details/getMaleFemaleCount", "GET").then(
        (res) => {
          if (res.data) {
            this.maleCnt = res.data.male;
            this.femaleCnt = res.data.female;
            this.maleFemaleData = {
              label: ["Male", "Female"],
              data: [res.data.male, res.data.female],
            };
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    if (this.$store.state.expiryDate < Date.now()) {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setIsAuthenticated", 0);
      this.render = true;
      this.$router.push("/");
      // location.reload();
    }
  },

  mounted() {
    this.initialize();
  },
};
</script>

