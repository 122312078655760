<template>
  <div class="mx-2 ">
    <!-- <div
    class="pl-2 pt-2 pr-2 mx-2 fill-height"
    style="background-color: white; overflow-y: hidden; overflow-x: hidden"
  > -->
    <v-card class="mt-2">
      <v-row>
        <v-col cols="12">
          <!-- <v-toolbar flat class="rounded-xl mb-7">
            <v-toolbar-title
              ><strong>{{ $route.meta.title }}</strong></v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar> -->
          <LeavesDataTable />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    LeavesDataTable: () => import("../../components/Utils/LeavesDataTable.vue"),
  },

  created() {
    // console.log("created");
    if (this.$store.state.expiryDate < Date.now()) {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setIsAuthenticated", 0);
      this.render = true;
      this.$router.push("/");
    }
  },
  data: () => ({
    typeData: [],
    action: null,
  }),
  methods: {
    addOffice() {
      this.typeData = [{ id: null }];
      this.action = "Add";
    },
  },
};
</script>

<style></style>
