<template>
  <div class="mt-2">
    <v-navigation-drawer
      persistent
      v-model="drawer"
      :permanent="$vuetify.breakpoint.smAndUp"
      :mini-variant.sync="mini"
      :temporary="!$vuetify.breakpoint.smAndUp"
      app
      style="background-color: #147452"
    >
      <!-- <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    style="background-color: transparent"
  > -->
      <v-list-item class="px-2" style="margin-top: 5px">
        <v-list-item-avatar>
          <v-img src="../../assets/img/DNSC_LOGO.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-title class="white--text font-weight-bold"
          >DNSC - HIS
        </v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-icon class="white--text">mdi-menu-open</v-icon>
        </v-btn>
        <v-btn icon @click="closeNav()" v-if="!$vuetify.breakpoint.smAndUp">
          <v-icon class="white--text">mdi-menu-open</v-icon>
        </v-btn>
      </v-list-item>

      <v-list nav dense class="sidebar mt-2">
        <div v-for="(link, i) in links" :key="i" style="background: #147452">
          <v-list-item
            v-if="!link.subLink"
            :key="link.title"
            router
            :to="'/' + userType + link.route"
            color="#808191"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :key="link.title" color="#3a3b3a" :value="false">
            <v-icon slot="prependIcon">{{ link.icon }}</v-icon>
            <template v-slot:activator>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </template>
            <div class="sub-item">
              <v-list-item
                v-for="sublink in link.subLink"
                router
                :to="'/' + userType + sublink.route"
                :key="sublink.title"
                color="#808191"
              >
                <v-list-item-icon>
                  <!-- <v-icon class="">{{ sublink.icon }}</v-icon> -->
                </v-list-item-icon>
                <v-list-item-title class="">{{
                  sublink.title
                }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!-- <v-app-bar app flat> -->
    <v-app-bar app flat color="#147452 ">
      <div
        class="d-flex elevation-4 mt-6 px-2 align-center rounded-t-lg"
        style="background-color: white; width: 100%; height: 60px"
      >
        <v-btn icon v-if="!$vuetify.breakpoint.smAndUp">
          <v-icon class="text-green-6DB249" @click="openMobileNav()"
            >mdi-menu-open</v-icon
          >
        </v-btn>
        <div class="pt-4" v-show="$vuetify.breakpoint.smAndUp">
          <h4 class="text-gray-100">Welcome {{ $store.state.user.fname }}!</h4>
          <p class="text-caption text-gray-200">{{ getCurrentDate() }}</p>
        </div>
        <v-spacer></v-spacer>

        <!-- <v-btn icon>
        <v-icon class="text-green-6DB249">mdi-bell</v-icon>
      </v-btn> -->
        <v-menu class="">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mr-4">
              <v-badge
                :content="notif_cnt == 0 ? null : notif_cnt"
                :color="notif_cnt == 0 ? '' : 'error'"
              >
                <v-icon class="text-green-6DB249">mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-card width="375">
            <v-list
              style="padding: 0; height: auto; margin: 0; max-height: 55vh"
            >
              <v-subheader class="notifDiv white--text text-center"
                ><div>
                  <span>NOTIFICATIONS</span> (<span class="text-caption">{{
                    notification_items.length
                  }}</span
                  >)
                </div>
                <span
                  class="gray--text text-caption"
                  @click="markAllAsRead(notification_items)"
                  style="cursor: pointer"
                  >Mark all as read</span
                ></v-subheader
              >
              <template>
                <!--  v-if="notification_items.length > 0" -->
                <div
                  v-if="notification_items.length > 0"
                  style="
                    padding: 0;
                    overflow: hidden;
                    overflow-y: auto;
                    background-color: white;
                  "
                >
                  <v-hover
                    v-for="item in notification_items"
                    :class="item.isOpenned == 0 ? 'unopened' : 'opened'"
                    :key="item.id"
                    v-slot="{ hover }"
                  >
                    <v-list-item
                      @click="markOneAsRead(item, 'notif')"
                      :class="`elevation-${hover ? 1 : 0}`"
                      style="cursor: pointer"
                    >
                      <v-list-item-avatar>
                        <v-icon size="large" color="teal-darken-2">{{
                          item.isOpenned ? "mdi-email-open" : "mdi-email"
                        }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <!-- <v-list-item-title>
                          <p class="grey--text text-caption">
                            {{ item.notification_type }}
                          </p>
                        </v-list-item-title> -->
                        <v-list-item-title>
                          <span class="font-weight-bold grey--text">{{
                            item.notif_from_name
                          }}</span
                          ><span
                            class=" "
                            :class="
                              item.notification_type == 'LOCATOR SLIP'
                                ? 'notifBadgeYellow'
                                : 'notifBadgePurple'
                            "
                            >{{ item.notification_type }}</span
                          ><br />
                          <span
                            class=" 
                            grey--text"
                            style="font-size: 12px; margin: 5px 0 5px 0"
                          >
                            {{ item.msg }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          ><p class="notif_msg">
                            {{ formatDateTimeAgo(item.created_at) }}
                          </p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-hover>
                </div>
                <div v-else>
                  <p
                    class="text-caption text-center"
                    style="margin: 5px 0 5px 0"
                  >
                    <i>No notifications found.</i>
                  </p>
                </div>
              </template>

              <v-hover class="showAllNotifDiv">
                <div class="showAllNotif" @click="showAllNotifDialog = true">
                  <v-icon size="medium" color="white">{{ "mdi-eye" }}</v-icon>
                  Show all notifications
                </div>
              </v-hover>
            </v-list>
          </v-card>
        </v-menu>
        <v-dialog width="800" max-height="auto" v-model="showAllNotifDialog">
          <!-- <template v-slot:activator="{ props }">
            <v-btn v-bind="props" text="Open Dialog"> </v-btn>
          </template> -->

          <template>
            <v-card title="Dialog">
              <v-card-title class="modal_header white--text"
                >All notifications</v-card-title
              >
              <v-card-text style="margin-top: 20px; width: 40%"
                ><v-text-field
                  v-model="search"
                  outlined
                  prepend-inner-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  class="rounded-lg"
                  color="#519043"
                  dense
                ></v-text-field
              ></v-card-text>
              <v-card-text outlined>
                <v-data-table
                  class="notifDataTable"
                  :headers="headers"
                  :items="notification_items"
                  :items-per-page="10"
                  :search="search"
                  hide-default-footer
                >
                  <template v-slot:[`item.notif_from_name`]="{ item }">
                    <span class="font-weight-bold">{{
                      item.notif_from_name
                    }}</span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDate(item.created_at) }}
                  </template>

                  <template v-slot:[`item.isOpenned`]="{ item }">
                    <!-- <v-btn
                      x-small
                      color="grey"
                      outlined
                      @click="viewItem(item)"
                    >
                      <v-icon size="14">{{
                        tab == 1 ? "mdi-pencil" : "mdi-eye"
                      }}</v-icon>
                      {{ tab == 1 ? "update" : "view" }}
                    </v-btn> -->
                    <v-btn
                      v-if="item.isOpenned == 0"
                      x-small
                      color="#519043"
                      outlined
                      @click="markOneAsRead(item, 'modal')"
                    >
                      <v-icon size="14">{{ "mdi-check" }}</v-icon>
                      Mark as Opened
                    </v-btn>
                    <!-- <v-item-text
                      style="color: #519043; cursor: pointer"
                      @click="markOneAsRead(item)"
                      >Mark as Read</v-item-text
                    > -->
                    <v-item-text
                      v-if="item.isOpenned == 1"
                      style="color: #e68c24; text-align: center"
                      >ALREADY OPENED</v-item-text
                    >
                  </template>
                </v-data-table>
              </v-card-text>

              <v-card-actions style="border-top: 1px solid rgb(239, 234, 234)">
                <v-spacer></v-spacer>

                <v-btn text="Close Dialog" @click="showAllNotifDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" color="white" class="rounded-lg d-flex py-2">
              <v-avatar left :size="$vuetify.breakpoint.smAndUp ? 100 : 100">
                <img :src="profImg" max-width="100" />
              </v-avatar>
              <span
                v-if="$vuetify.breakpoint.smAndUp"
                style="width: 130px; text-align: center"
                ><strong
                  >{{ $store.state.user.fname }}
                  {{ $store.state.user.lname.charAt(0).toUpperCase() }}.</strong
                ><br />{{ $store.state.user.usertype.description }} /
                {{ getMyRole($store.state.user.user.user_roleID) }}
              </span>
              <v-icon right> mdi-chevron-down </v-icon>
            </v-chip>
          </template>
          <v-card width="240">
            <v-list color="#147452">
              <v-list-item>
                <v-list-item-avatar>
                  <img :src="profImg" max-width="60" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="white--text"
                    >{{ $store.state.user.fname }}
                    {{
                      $store.state.user.lname.charAt(0).toUpperCase()
                    }}.</v-list-item-title
                  >
                  <v-list-item-subtitle class="white--text"
                    >{{ $store.state.user.usertype.description }} /
                    {{ getMyRole($store.state.user.user.user_roleID) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="menu = false">
                    <v-icon class="white--text">mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item @click="toProfile()">
                <v-list-item-action>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle
                  ><strong>Profile</strong></v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle
                  ><strong>Sign Out </strong></v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: ["screenSmall", "reloadImg", "isOIC"],
  watch: {
    screenSmall: {
      handler(val) {
        this.drawer = val;
        this.mini = false;
      },
    },
    reloadImg: {
      handler() {
        this.loadImg();
      },
    },
    isOIC: {
      handler() {
        let userType = this.$store.state.user.user.usertypeID;
        let userrole = this.$store.state.user.user.user_roleID;
        this.loadMenu(userType, userrole);
      },
    },
  },
  data() {
    return {
      search: "",
      drawer: true,
      mini: false,
      profImg: null,
      mobile: false,
      userRoleList: [],
      links: [],
      userType: null,
      notification_items: [],
      notif_cnt: 0,
      showAllNotifDialog: false,
      interval: null,
      loading: false,
      options: [],
      headers: [
        {
          text: "From",
          value: "notif_from_v-list-subtitlev-list-subtitle",
          align: "start",
        },
        {
          text: "Notification Content",
          value: "msg",
          align: "start",
        },
        { text: "Date Created", value: "created_at", align: "start" },
        { text: "", value: "isOpenned", align: "center" },
      ],
    };
  },

  mounted() {
    this.loadUserRoles();
    if (this.$store.state.expiryDate < Date.now()) {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setIsAuthenticated", 0);
      // this.render = true;
      this.$router.push("/");

      // location.reload();
    }

    this.loadImg();
    this.getMyNotifs();
    this.getMyNewNotifsCount();
    // this.onResize();
    // window.addEventListener("resize", this.onResize, { passive: true });

    if (this.$vuetify.breakpoint.xs) {
      this.drawer = false;
      this.mini = false;
    }
  },

  methods: {
    getMyRole(id) {
      var role;
      for (let i = 0; i < this.userRoleList.length; i++) {
        if (parseInt(this.userRoleList[i].id) == parseInt(id)) {
          role = this.userRoleList[i].description;
        }
      }
      return role;
    },

    openMobileNav() {
      this.drawer = true;
    },
    loadImg() {
      this.axiosCall("/user-details/getUserProfileImg", "GET").then((res) => {
        this.profImg =
          process.env.VUE_APP_SERVER +
          "/user-details/getProfileImg/" +
          res.data.profile_img;
      });
    },
    toProfile() {
      var usertype = this.$store.state.user.usertype.description;
      if (this.$store.state.user.user.user_roleID == 5) {
        this.$router.push("/superadmin/profile");
      } else {
        this.$router.push("/" + usertype.toLowerCase() + "/profile");
      }
    },
    getCurrentDate() {
      const cur_date = new Date();
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const day = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return (
        day[cur_date.getDay()] +
        ", " +
        month[cur_date.getMonth()] +
        " " +
        cur_date.getDate() +
        "; " +
        this.formatAMPM(cur_date)
      );
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    // onResize() {
    //   if (window.innerWidth < 600) {
    //     console.log("triggered");
    //     this.drawer = false;
    //     this.mini = false;
    //   }
    // },
    closeNav() {
      this.drawer = false;

      this.$emit("closeNav");
    },

    getModules(usertypeID) {
      this.axiosCall("/usertype-module/usertype/" + usertypeID, "GET").then(
        (res) => {
          if (res) {
            // this.modules = res.data;
            // console.log(res.data);
          }
        }
      );
    },

    markAllAsRead(data) {
      this.axiosCall("/notifications/markAllAsRead", "POST", data).then(
        (res) => {
          if (res) {
            // console.log(res.data);
            this.getMyNotifs();
            this.getMyNewNotifsCount();
            this.loadUserRoles();
            // this.loadMenu();
            // this.getModules();
          }
        }
      );
    },

    markOneAsRead(data, from) {
      console.log(data);
      if (from == "notif") {
        this.axiosCall("/notifications/markOneAsRead", "POST", data).then(
          (res) => {
            if (res) {
              console.log(data);
              this.getMyNotifs();
              this.getMyNewNotifsCount();
              this.loadUserRoles();
              // this.loadMenu();
              // this.getModules();
              this.$router.push("/" + this.userType + data.redirect_route);
            }
          }
        );
      } else {
        this.axiosCall("/notifications/markOneAsRead", "POST", data).then(
          (res) => {
            if (res) {
              this.getMyNotifs();
              this.getMyNewNotifsCount();
              this.loadUserRoles();
              // this.loadMenu();
              // this.getModules();
            }
          }
        );
      }
    },

    getMyNotifs() {
      this.axiosCall("/notifications/getMyNotifs", "GET").then((res) => {
        if (res) {
          // console.log(res.data);
          this.notification_items = res.data;
        }
      });
    },

    getMyNewNotifsCount() {
      this.axiosCall("/notifications/getMyNewNotifsCount", "GET").then(
        (res) => {
          if (res) {
            // console.log(res.data);
            this.notif_cnt = res.data;
          }
        }
      );
    },

    loadUserRoles() {
      this.axiosCall("/user-role/", "GET").then((res) => {
        if (res.data) {
          // console.log(res.data);
          this.userRoleList = res.data;
        }
      });
    },

    loadMenu(userType, userRole) {
      // console.log("called hwere", this.isActiveOIC);
      this.axiosCall("/my-oic/active", "GET").then((res) => {
        if (res.data.hasActive) {
          this.axiosCall("/assigned-modules/getMyModuleAsOIC", "GET").then(
            (resp) => {
              this.links = JSON.parse(resp.data.assign_mods);
              switch (userType) {
                case 1:
                  this.userType = "admin";

                  break;
                case 2:
                  if (userRole == 5) {
                    this.userType = "superadmin";
                  } else {
                    this.userType = "employee";
                  }
                  break;
                case 3:
                  this.userType = "security";

                  break;
                case 4:
                  this.userType = "auditor";

                  break;
              }
            }
          );
        } else {
          this.axiosCall(
            "/assigned-modules/getMyAssignedModules/my",
            "GET"
          ).then((resp) => {
            console.log(resp);
            this.links = JSON.parse(resp.data.assign_mods);
            switch (userType) {
              case 1:
                this.userType = "admin";

                break;
              case 2:
                if (userRole == 5) {
                  this.userType = "superadmin";
                } else {
                  this.userType = "employee";
                }
                break;
              case 3:
                this.userType = "security";

                break;
              case 4:
                this.userType = "auditor";

                break;
            }
          });
        }
      });
      // if (this.isActiveOIC) {
      //   // this.axiosCall("/assigned-modules/getMyModuleAsOIC", "GET").then(
      //   //   (res) => {
      //   //     console.log("asdasd", res.data.assign_mods);
      //   //     this.links = JSON.parse(res.data.assign_mods);
      //   //     switch (userType) {
      //   //       case 1:
      //   //         this.userType = "admin";

      //   //         break;
      //   //       case 2:
      //   //         if (userRole == 5) {
      //   //           this.userType = "superadmin";
      //   //         } else {
      //   //           this.userType = "employee";
      //   //         }
      //   //         break;
      //   //       case 3:
      //   //         this.userType = "security";

      //   //         break;
      //   //       case 4:
      //   //         this.userType = "auditor";

      //   //         break;
      //   //     }
      //   //   }
      //   // );
      // } else {
      //   // this.axiosCall("/assigned-modules/getMyAssignedModules/my", "GET").then(
      //   //   (res) => {
      //   //     console.log(res);
      //   //     // this.links = JSON.parse(res.data.assign_mods);
      //   //     switch (userType) {
      //   //       case 1:
      //   //         this.userType = "admin";

      //   //         break;
      //   //       case 2:
      //   //         if (userRole == 5) {
      //   //           this.userType = "superadmin";
      //   //         } else {
      //   //           this.userType = "employee";
      //   //         }
      //   //         break;
      //   //       case 3:
      //   //         this.userType = "security";

      //   //         break;
      //   //       case 4:
      //   //         this.userType = "auditor";

      //   //         break;
      //   //     }
      //   //   }
      //   // );
      // }

      // this.axiosCall("/core-time-designation/getMyDesignation", "GET").then(
      //   (res) => {
      //     if (res) {
      //       switch (userType) {
      //         case 1:
      //           this.userType = "admin";
      //           this.links = this.admin_links;
      //           break;
      //         case 2:
      //           this.userType = "employee";
      //           this.links = this.employee_links;

      //           if (res.data.length > 0) {
      //             this.links.splice(this.links.length - 1, 0, {
      //               title: "TO Approval",
      //               icon: "mdi-bus-clock",
      //               route: "/to-approval",
      //             });
      //             this.links.splice(this.links.length - 1, 0, {
      //               title: "LS Approval",
      //               icon: "mdi-text-box-multiple",
      //               route: "/locators",
      //             });
      //           }
      //           break;
      //         case 3:
      //           this.userType = "security";
      //           this.links = this.securityGuard_links;
      //           break;
      //       }
      //     }
      //   }
      // );
    },
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },

  created: function() {
    // this.getMyAssignedModules();
    let userType = this.$store.state.user.user.usertypeID;
    let userrole = this.$store.state.user.user.user_roleID;
    // this.getMyDesignation();
    this.loadMenu(userType, userrole);

    this.interval = setInterval(() => {
      this.getMyNewNotifsCount();
      this.getMyNotifs();
    }, 180000);
  },
};
</script>

<style scoped>
.modal_content {
  padding: 5px 0 5px 0;
}
.modal_header {
  background-color: #519043;
  padding: 5px;
}
.notifDiv {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #519043;
  color: white;
  flex: auto;
  justify-content: space-between;
}
.showAllNotifDiv {
  position: sticky;
  bottom: 0;
  cursor: pointer;
}
.showAllNotif {
  margin: 0;
  font-size: 12px;
  background-color: #519043;
  padding: 10px;
  text-align: center;
  max-height: 55vh;
  color: white;
}
.unopened {
  /* top right bottom left */
  margin: 10px;
  /* border-bottom: 0.5px solid #cbcec7; */
  border-radius: 10px;
  background-color: #e7f6cd;
}
.unopened:hover {
  background-color: #d8ff8f;
}
.opened {
  /* top right bottom left */
  margin: 10px;
  border: 0.5px solid #e6ebe1;
  border-radius: 10px;
}
.opened:hover {
  background-color: #d8ff8f;
}
::v-deep .v-toolbar__content {
  padding: 8px !important;
}
.v-list-item__icon {
  color: white !important;
}
.sidebar .v-list-item {
  border-radius: 5px;
  /* margin-left: 10px; */
  color: white;
}

.notifBadgeYellow {
  background-color: rgba(255, 255, 34, 0.5);
  /* border: 1px solid rgb(255, 217, 0); */
  padding: 3px;
  border-radius: 4px;
  font-size: 10px;
  color: rgb(140, 140, 14);
  margin: 0px 0px 0px 5px;
}

.notifBadgePurple {
  background-color: rgba(255, 138, 255, 0.5);
  /* border: 1px solid rgb(255, 217, 0); */
  padding: 3px;
  border-radius: 4px;
  font-size: 10px;
  color: purple;
  margin: 0px 0px 0px 5px;
}

.notifBadgeBlue {
  background-color: rgba(138, 210, 255, 0.5);
  /* border: 1px solid rgb(255, 217, 0); */
  padding: 3px;
  border-radius: 4px;
  font-size: 10px;
  color: rgb(0, 75, 128);
  margin: 0px 0px 0px 5px;
}

.notifBadgeGreen {
  background-color: rgba(157, 255, 138, 0.5);
  /* border: 1px solid rgb(255, 217, 0); */
  padding: 3px;
  border-radius: 4px;
  font-size: 10px;
  color: rgb(21, 128, 0);
  margin: 0px 0px 0px 5px;
}

.sidebar .v-list-item:hover {
  /* background-color: rgba(255, 247, 247, 0.949);
  color: black; */
  border-radius: 5px;
  transition: 0.5s;
}

.sidebar .v-list-item--active .v-list-item__title {
  color: #3a3b3a !important;
}

.sidebar .v-list-item--active .v-list-item__icon i {
  color: #3a3b3a !important;
}

.v-list-group--active .man {
  color: #3a3b3a !important;
}

.sidebar .v-list-item--active {
  background-color: white !important;
  color: #3a3b3a !important;
}
.sidebar .v-list-group--active {
  background-color: white !important;
  border-radius: 5px;

  color: #3a3b3a !important;
}

.sidebar div .sub-item .v-list-item {
  background-color: white !important;
}

.sidebar div .sub-item .v-list-item .v-list-item__title {
  color: #3a3b3a !important;
}

.sidebar div .sub-item .v-list-item .v-list-item__icon i {
  color: #3a3b3a !important;
}

.sidebar div .sub-item .v-list-item--active {
  background-color: #bcedc8 !important;
  color: #3a3b3a !important;
}

.active_grp {
  background-color: white;
}

.nav-drawer .item-title {
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
.v-subheader {
  border-bottom: 1px solid #cdcbd0;
  color: green;
}
.notif_msg {
  font-size: 10px;
}
.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8;
}
.sub-item {
  border-radius: 5px;
  background: white;
}

.v-list-item__title {
  color: white !important;
}

.v-list-item__icon i {
  color: white !important;
}

/* 
.v-list-group {
  color: white !important;
} */

.v-hover {
  border-color: grey !important;
}
.v-list-group .v-list-group--active {
  color: #3a3b3a !important;
}
::v-deep .v-list-group i {
  color: white !important;
}

::v-deep .v-list-group--active i {
  color: #3a3b3a !important;
}

/* .v-list-group__header__append-icon {
  display: none !important;
} */
</style>
