<template>
  <div class="mx-2">
    <v-card class="mt-2 d-flex justify-center">
      <img src="../../assets/img/Frame_dashboard.jpg" alt="" />
      <!-- <v-row class="pa-2 mt-4">
        <v-col cols="4" sm="12" md="4">
          <v-card class="card-style">
            <v-row>
              <v-col cols="12" class="pa-5 d-flex justify-center text-h3">
                21
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="text-subtitle-2 w-full d-flex justify-center"
                  >My Leave Balance</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4" sm="12" md="4">
          <v-card class="card-style">
            <v-row>
              <v-col cols="12" class="pa-5 d-flex justify-center text-h3">
                21
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="text-subtitle-2 w-full d-flex justify-center"
                  >My Something 1</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4" sm="12" md="4">
          <v-card class="card-style">
            <v-row>
              <v-col cols="12" class="pa-5 d-flex justify-center text-h3">
                21
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <v-list-item-title
                  color="#808191"
                  class="text-subtitle-2 w-full d-flex justify-center"
                  >My Something 2</v-list-item-title
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-card class="card-style">
            <v-col cols="12">
              <BarChart />
            </v-col>
          </v-card>
        </v-col>
      </v-row> -->
    </v-card>
    <ViewSetDirectHeadDialog :data="head_data" />
  </div>
</template>

<script>
// import BarChart from "../../components/Charts/samples/BarChart.vue";
import ViewSetDirectHeadDialog from "../../components/Dialogs/Views/ViewSetDirectHeadDialog.vue";
export default {
  components: {
    //  BarChart
    ViewSetDirectHeadDialog,
  },
  data: () => ({
    mini: false,
    scheduledWorks: null,
    dispatchWorks: null,
    cancelledWorks: null,
    completedWorks: null,
    datas: [],
    head_data: {},
    label: [],
    top_clients: [],
  }),
  created() {
    // console.log("created");
    if (this.$store.state.expiryDate < Date.now()) {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setIsAuthenticated", 0);
      this.render = true;
      this.$router.push("/");
      // location.reload();
    }
  },
  methods: {
    initialize() {
      this.getIfHasDirectHead();
      this.getMyDirectHead();

      // this.getMyDirectHead();
      // let statuses = [1,3,5,6]
      // this.axiosCall("/dashboard/getAllWorkStatusCount", "POST", statuses).then((res) => {
      //   if (res){
      //       this.scheduledWorks = res.data.worksArray[0].work_detail_status_count
      //       this.dispatchWorks = res.data.worksArray[1].work_detail_status_count
      //       this.cancelledWorks = res.data.worksArray[2].work_detail_status_count
      //       this.completedWorks = res.data.worksArray[3].work_detail_status_count
      //       this.$refs.DoughnutChart1.renderGraph(res.data.total.total_works,res.data.worksArray[0].work_detail_status_count)
      //       this.$refs.DoughnutChart2.renderGraph(res.data.total.total_works,res.data.worksArray[1].work_detail_status_count)
      //       this.$refs.DoughnutChart3.renderGraph(res.data.total.total_works,res.data.worksArray[2].work_detail_status_count)
      //       this.$refs.DoughnutChart4.renderGraph(res.data.total.total_works,res.data.worksArray[3].work_detail_status_count)
      //   }
      // });
      // this.axiosCall("/dashboard/getAllTopClients", "GET").then((res) => {
      //   if (res){
      //       this.top_clients = res.data
      //   }
      // });
      // this.axiosCall("/dashboard/getAllWorkRequest", "GET",).then((res) => {
      //   if (res){
      //       res.data.forEach(item => {
      //         this.label.push(item.request_type_description)
      //         this.datas.push(item.work_request_count)
      //       });
      //     this.$refs.PieChart.renderGraph(this.datas,this.label)
      //   }
      // });
      // console.log(this.$store.getters.getIsAuthenticated);
    },
    getMyDirectHead() {
      this.axiosCall("/user-details/getMyDirectHead", "GET").then(
        (res) => {
          console.log(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getIfHasDirectHead() {
      this.axiosCall("/my-direct-head/getIfHasDirectHead", "GET").then(
        (res) => {
          console.log("hasdirecthead", res.data);
          this.head_data = res.data;
        }
      );
    },
  },
  mounted() {
    // if (this.$store.state.expiryDate < Date.now()) {
    //   this.$store.dispatch("setUser", null);
    //   this.$store.dispatch("setIsAuthenticated", 0);
    //   this.render = true;
    //   this.$router.push("/");

    //   // location.reload();
    // }
    this.initialize();
  },
};
</script>
